import React from 'react';
import classnames from 'classnames';
import { namespace, filterIntervensionMobilePropTypes } from './constants';
import { renderAvailableFilters, renderInterventionContent } from './components';

const FilterIntervention = ({ intervention }) => {
  const { type, title, filter, background_color } = intervention;
  const isTopBrand = filter.id === 'BRAND' && type === 'FILTER_SPECIALIZED';

  return (
    <div className={classnames(namespace, `${namespace}-container`, `${namespace}-container__${type}`)}>
      <div
        className={classnames(
          `${namespace}-background`,
          background_color ? `${namespace}-${background_color}` : `${namespace}-gray-040-solid`,
        )}
      >
        <span className={`${namespace}-title`}>
          {filter?.see_all_title?.text}
          <span className={`${namespace}-title__SEMIBOLD`}> {title.text}</span>
        </span>
        {renderAvailableFilters(filter, title, isTopBrand)}
        {renderInterventionContent(type, filter, title, isTopBrand)}
      </div>
    </div>
  );
};

FilterIntervention.propTypes = {
  ...filterIntervensionMobilePropTypes,
};

export default FilterIntervention;
