import React from 'react';
import classnames from 'classnames';
import SpecializedCarousel from './specialized-carousel/desktop';
import { FILTER_INTERVENTION, namespace, filterIntervensionDesktopPropTypes } from './constants';

const FilterIntervention = ({ intervention: { type, title, filter }, deviceType }) => (
  <div className={classnames(namespace, `${namespace}-container`, `${namespace}-container__${type}`)}>
    {type === FILTER_INTERVENTION.TYPES.FILTER_SPECIALIZED && (
      <SpecializedCarousel type={type} filter={filter} title={title} deviceType={deviceType} />
    )}
  </div>
);

FilterIntervention.propTypes = {
  ...filterIntervensionDesktopPropTypes,
};

export default FilterIntervention;
