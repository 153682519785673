import React from 'react';
import classnames from 'classnames';
import StyledLabelWrapper from '../../../../../components/styled-label/styled-label';
import { CarouselSnappedSlide } from '@andes/carousel-snapped';
import { Image } from 'nordic/image';
import { Thumbnail } from '@andes/thumbnail';
import { namespace, SLIDES_LIMIT } from '../constants';

export const getSeeMoreButton = (slide, openModal, index) => (
  <CarouselSnappedSlide key={`${index}-see-more`} className={`${namespace}-specialized-item-slide`}>
    <button type="button" onClick={openModal} className={`${namespace}-specialized-see-more`}>
      <Thumbnail size="80">
        <Image
          alt={slide.picture.tags.alt}
          lazyload={index < SLIDES_LIMIT ? 'off' : 'on'}
          preload={index < SLIDES_LIMIT}
          src={slide.picture.normal}
        />
      </Thumbnail>
      <StyledLabelWrapper
        className={classnames(`${namespace}-specialized-title-short`)}
        color={slide.label.color}
        font_weight={slide.label.font_family}
        size={slide.label.font_size}
        text={slide.label.text}
        as="span"
      />
    </button>
  </CarouselSnappedSlide>
);
