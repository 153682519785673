import React from 'react';
import classnames from 'classnames';
import Link from '../../../../link';
import StyledLabelWrapper from '../../../../../components/styled-label/styled-label';
import { Image } from 'nordic/image';
import { Thumbnail } from '@andes/thumbnail';
import { CarouselSnappedSlide } from '@andes/carousel-snapped';
import { SEE_MORE, SLIDES_LIMIT, namespace } from '../constants';
import { isTitleOneLine } from '../../helpers';
import { getSeeMoreButton } from './seeMore';

export const renderSlidesDesktop = (slide, index, openModal) => {
  if (slide.id !== SEE_MORE) {
    return (
      <CarouselSnappedSlide key={`${index}-${slide.label.text}`} className={`${namespace}-specialized-item-slide`}>
        <Link className={`${namespace}__link`} href={slide.target} filterId={slide.id} title={slide.picture.tags.alt}>
          <Thumbnail size="80">
            <Image
              alt=""
              lazyload={index < SLIDES_LIMIT ? 'off' : 'on'}
              preload={index < SLIDES_LIMIT}
              src={slide.picture.normal}
            />
          </Thumbnail>
          <StyledLabelWrapper
            className={classnames(
              `${namespace}${
                isTitleOneLine(slide.label.text) ? '-specialized-title-short' : '-specialized-title-large'
              }`,
            )}
            color={slide.label.color}
            font_weight={slide.label.font_family}
            size={slide.label.font_size}
            text={slide.label.text}
            as="span"
          />
          {slide.subtitle && <span className={`${namespace}-specialized-subtitle`}>{slide.subtitle.text}</span>}
        </Link>
      </CarouselSnappedSlide>
    );
  }

  return getSeeMoreButton(slide, openModal, index);
};
