import React from 'react';
import ColorCarouselSlide from './components/slides';
import { CarouselFree } from '@andes/carousel-free';
import { namespace, colorCarouselPropTypes, SPACING } from './constants';

const ColorCarousel = ({ filter }) => {
  const { values } = filter;

  return (
    <CarouselFree spacing={SPACING} className={`${namespace}-carousel`}>
      {values.map((slide) => (
        <ColorCarouselSlide key={slide.id} slide={slide} />
      ))}
    </CarouselFree>
  );
};

ColorCarousel.propTypes = {
  ...colorCarouselPropTypes,
};

export default ColorCarousel;
